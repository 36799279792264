import { Injectable } from '@angular/core';
import { marked, MarkedOptions } from 'marked';

@Injectable({
  providedIn: 'root',
})
export class MarkdownService {
  parseInlineMarkdown(
    text: string,
    options?: MarkedOptions
  ): string | Promise<string> {
    const optionsWithDefaults: MarkedOptions = {
      ...options,
    };

    return marked.parseInline(text, optionsWithDefaults);
  }

  parseMarkdown(
    text: string,
    options?: MarkedOptions
  ): string | Promise<string> {
    const optionsWithDefaults: MarkedOptions = {
      ...options,
    };

    return marked.parse(text, optionsWithDefaults);
  }
}
